import { CurrentUserFacade } from '@awread/core/users';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, tap } from 'rxjs/operators';
import { format, formatRelative, startOfDay, subDays } from 'date-fns';
import vi from 'date-fns/locale/vi';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { enUS } from 'date-fns/locale';
@Injectable({ providedIn: 'root' })
export class DepositsApi {
  constructor(
    private apollo: Apollo,
    private currentUserFacade: CurrentUserFacade,
    private httpClient: HttpClient,
  ) { }

  sendNotiDepositMail(email: string = 'hiepxanh@gmail.com') {
    const url = `/api/notifications/noti-deposit?email=${email}`;
    return this.httpClient.get(`${url}`);
  }

  getAllDeposits() {
    return this.apollo
      .query<any>({
        query: gql`
          query allDeposits {
            deposits(orderBy: TIMESTAMP_DESC, filter: { payment: { distinctFrom: "system" } }) {
              nodes {
                depositId
                depositNid
                nodeId
                payment
                status
                timestamp
                type
                userId
                user {
                  username
                  userNid
                }
                value
              }
            }
          }
        `,
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((res) => res?.['data']?.['deposits']?.['nodes']),
        map((res) => res.map((item: any) => ({ ...item, timeRelative: format(new Date(item.timestamp), 'Pp', { locale: vi }) }))),
      );
  }

  getAllDepositsByUserId() {
    return this.apollo
      .query<any>({
        query: gql`
          query getAllDepositsByUserId($userId: UUID!) {
            deposits(orderBy: TIMESTAMP_DESC, condition: { userId: $userId }) {
              nodes {
                depositId
                depositNid
                nodeId
                payment
                status
                timestamp
                type
                userId
                value
                memo
                balance
              }
            }
          }
        `,
        variables: {
          userId: this.currentUserFacade.getUserId(),
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((res) => res?.['data']?.['deposits']?.['nodes']),
        map((res) =>
          res.map((item: any) => ({
            ...item,
            timeRelative: format(new Date(item.timestamp), 'Pp', { locale: vi }),
            coin: Math.floor(Number(item.value)).toLocaleString('it-IT'),
          })),
        ),
      );
  }

  getDepositPendingByUserId() {
    return this.apollo
      .query<any>({
        query: gql`
          query getDepositPendingByUserId($userId: UUID!, $status: String = "pending") {
            deposits(condition: { userId: $userId, status: $status }) {
              nodes {
                depositId
                depositNid
                nodeId
                payment
                status
                timestamp
                type
                userId
                value
              }
            }
          }
        `,
        variables: {
          userId: this.currentUserFacade.getUserId(),
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((res) => res?.['data']?.['deposits']?.['nodes']));
  }

  createDeposit(depositNid: any, value: any, payment: any, userId: any) {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation createDeposit($depositNid: String, $value: BigFloat, $payment: String, $userId: UUID!) {
            createDeposit(input: { deposit: { depositNid: $depositNid, value: $value, payment: $payment, status: "pending", userId: $userId } }) {
              deposit {
                depositId
                depositNid
                nodeId
                payment
                status
                timestamp
                type
                userId
                value
              }
            }
          }
        `,
        variables: {
          userId,
          depositNid,
          value,
          payment,
        },
      })
      .pipe(map((res) => res?.['data']?.['createDeposit']?.['deposit']));
  }

  updateDeposit(depositId: any, status: any) {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation updateDeposit($status: String, $depositId: UUID!) {
            updateDeposit(input: { patch: { status: $status }, depositId: $depositId }) {
              deposit {
                depositId
                status
              }
            }
          }
        `,
        variables: {
          status,
          depositId,
        },
      })
      .pipe(map((res) => res?.['data']?.['updateDeposit']?.['deposit']));
  }


  releaseCoin(depositId: any, status: string) {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation releaseCoin($depositId: UUID!) {
            releaseCoin(input: {depositId: $depositId}) {
              json
            }
          }

        `,
        variables: {
          depositId,
          status
        },
      })
      .pipe(map((res) => res?.['data']?.['releaseCoin']?.['json']));
  }

  getUserWallet(userId: any) {
    return this.apollo
      .query<any>({
        query: gql`
          query getUserWallet($userId: UUID!) {
            wallet(userId: $userId) {
              balance
              nodeId
              userId
              limit
            }
          }
        `,
        variables: {
          userId,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((res) => res?.['data']?.['wallet']));
  }

  createLimit(limit: any) {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation createLimit($limit: BigFloat, $userId: UUID!) {
            createWallet(input: { wallet: { limit: $limit, userId: $userId } }) {
              wallet {
                balance
                userId
                limit
              }
            }
          }
        `,
        variables: {
          userId: this.currentUserFacade.getUserId(),
          limit,
        },
      })
      .pipe(map((res) => res?.['data']?.['createWallet']?.['wallet']));
  }

  updateLimit(limit: any) {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation updateLimit($limit: BigFloat, $userId: UUID!) {
            updateWallet(input: { patch: { limit: $limit }, userId: $userId }) {
              wallet {
                balance
                userId
                limit
              }
            }
          }
        `,
        variables: {
          userId: this.currentUserFacade.getUserId(),
          limit,
        },
      })
      .pipe(map((res) => res?.['data']?.['updateWallet']?.['wallet']));
  }

}
